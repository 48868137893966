.btn {
  width: 100%;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: $transition;

  text-transform: uppercase;
  text-decoration: none;
  line-height: 100%;
  text-align: center;
  font-weight: 700;
}

/*---------------------------------РАЗМЕРЫ------------------------------------*/

.btn_medium {
  padding: 21px 20px;
  font-size: 20px;
  @include tablet {
    font-size: 14px;
  }
}

.btn_small {
  padding: 10px 20px;
  font-size: 14px;
}


/*---------------------------------ПАЛИТРА------------------------------------*/

.btn_primary {
  background: linear-gradient(90deg, #44B0FF 0%, #8EED43 100%);
  color: $type-dark-primary;
  fill: $type-dark-primary;

  &:hover {
    background-color: #8EED43;
  }

  &:disabled {
    background: $type-dark-disable;
    color: $type-dark-disable;
    fill: $type-dark-disable;
  }
}

.btn_secondary {
  background: transparent;
  color: $type-dark-primary;
  fill: $type-dark-primary;
  box-shadow: inset 0 0 0 1px $ui-border-color;

  &:hover {
    background: transparent;
    box-shadow: inset 0 0 0 1px #FFFFFF;
  }

  &:disabled {
    background: transparent;
    color: $type-dark-disable;
    fill: $type-dark-disable;
  }
}
