@font-face {
  font-family: 'Mugwort Maximum';
  src: local('MugwortMaximum'), local('MugwortMaximum'),
  url('./fonts/MugwortMaximum/MugwortMaximum.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/Inter/Inter-Medium.ttf');
  src: local('Inter'), local('Inter'),
  url('fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
