@mixin nlg {
  @media all and (min-width: 1920px) {
    @content;
  }
}
@mixin laptop {
  @media all and (max-width: 1023.5px) {
    @content;
  }
}
@mixin tablet {
  @media all and (max-width: 820px) {
    @content;
  }
}
@mixin mobile {
  @media all and (max-width: 450px) {
    @content;
  }
}
