h1, h2, h3 {
  font-family: $font-heading;
  font-style: normal;
  color: $type-dark-primary;
}
h4, h5, h6, p {
  font-family: $font;
  font-style: normal;
  color: $type-dark-primary;
}
h1, h2, h3, h4, h5, h6 {
  letter-spacing: unset;
}

h1 {
  font-weight: 400;
  font-size: 160px;
  line-height: 100%;
  @include mobile {
    font-size: 120px;
  }
}

h2 {
  font-weight: 300;
  font-size: 60px;
  line-height: 100%;
  @include mobile {
    font-size: 11vw;
  }
}

h3 {
  font-weight: 400;
  font-size: 60px;
  line-height: 145%;
  @include mobile {
    font-size: 40px;
    line-height: 133%;
  }
}

h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

p {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  @include mobile {
    font-size: 16px;
  }
}
.text-2 {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  @include mobile {
    font-size: 4.5vw;
  }
}
.capture {
  margin: 0;
  font-weight: 400;
  line-height: 140%;
  font-size: 12px;
}
