* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}
