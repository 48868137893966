// app global css in SCSS form

@import "_fonts";
@import "_vars";
@import "_mixins";
@import "_reset";
@import "_btns";
@import "_inputs";
@import "_texts";

#q-app {
  background: #000;
  overflow: hidden;
}
.container {
  margin: 0 auto;
  max-width: 1260px;
  width: 100%;
  padding: 0 40px;
  @include mobile {
    padding: 0 5.5vw;
  }

  &_sm {
    max-width: 660px;
  }
  &_lg {
    max-width: 1920px;
  }
}
