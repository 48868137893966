.input {
  color: #FFFFFF;
}
.q-input {
  margin-bottom: 12px;
  box-shadow: inset 0 0 5px 0 #44B0FF;
  border-radius: 8px;
}
.error {
  color: $add-red-400;
  margin: 12px 0;
}
